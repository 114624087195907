import React from 'react';
import styled from 'styled-components';
import { Stack, List, media, Container } from '@tymate/margaret';
import { Link } from 'gatsby';
import { Button } from 'components';
import { fontStyles, fontSize } from 'ui';

const HeroWrapper = styled.div`
  background: ${({ theme }) => theme.primaryGradient};

  padding: ${({ theme }) => theme.spacing(3)} 0;
`;

const MainPicture = styled.div`
  display: flex;
  justify-content: center;
  max-width: 60vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  ${media.tablet`
    max-width: 440px;
  `}

  img {
    align-items: center;
    justify-content: center;
    display: block;
    object-fit: contain;
  }
`;

const HeroTitle = styled.h1`
  ${fontStyles.h1Mega};
  color: #fff;
  margin: 0;
`;

const HeroContent = styled.div`
  width: 660px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const HeroDescription = styled.div`
  color: #fff;
  font-weight: 500;
  line-height: 1.875;
  margin-top: ${({ theme }) => theme.spacing(1.5)};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;

const SpecialityGrid = styled(List)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  grid-gap: ${({ theme }) => theme.spacing(0.5)};

  ${media.desktop`
    grid-column-gap: ${({ theme }) => theme.spacing(2)};
    grid-template-columns: repeat(2, 1fr);
  `};
`;

const SpecialityLink = styled(Link)`
  ${fontSize.h3};
  font-weight: bold;
  text-decoration: none;
  color: ${({ theme }) => theme.text};

  &:hover {
    color: #fff;
  }
`;

const Hero = ({ data }) => (
  <HeroWrapper>
    <Container>
      <Stack
        alignX="space-between"
        alignY="center"
        gutterSize={2}
        direction={{ default: 'column', tablet: 'row' }}
      >
        <HeroContent>
          <HeroTitle>{data?.datoCmsHomepage?.mainTitle}</HeroTitle>
          <HeroDescription>
            {data?.datoCmsHomepage?.description}
          </HeroDescription>
          <SpecialityGrid>
            {data?.allDatoCmsSkill.edges.map(({ node }, index) => (
              <li key={index}>
                <SpecialityLink to={`/cabinet/${node.slug}`}>
                  {node.name}
                </SpecialityLink>
              </li>
            ))}
          </SpecialityGrid>
          <Button variant="primary" to="/coordonnees">
            Prendre RDV
          </Button>
        </HeroContent>

        <MainPicture>
          <div>
            <img src={data?.datoCmsHomepage?.mainImage?.url} alt="" />
          </div>
        </MainPicture>
      </Stack>
    </Container>
  </HeroWrapper>
);

export default Hero;
