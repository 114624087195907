import React from 'react';
import styled from 'styled-components';
import { media, Stack } from '@tymate/margaret';
import { fontStyles, fontSize, Label } from 'ui';
import { FaLinkedinIn } from 'react-icons/fa';

const Wrapper = styled.li`
  width: 100%;

  + li {
    padding-top: ${({ theme }) => theme.spacing(2)};
    border-top: 1px solid ${({ theme }) => theme.separator};
  }

  ${media.medium`
    + li {
      padding-top: 0;
      border-top: none;
    }
  `}
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.medium`
    display: grid;
    grid-gap: 70px;
    grid-template-columns: 1fr 410px;
    grid-template-areas: "content picture";

  ${({ inverted }) =>
    inverted &&
    `
      grid-template-columns: 410px 1fr;
      grid-template-areas: "picture content";
    `}
  `};
`;

const PictureWrapper = styled.div`
  grid-area: picture;
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  ${media.medium`
    margin-bottom: 0
  `};
`;

const ContentWrapper = styled.div`
  grid-area: content;
`;

const LaywerName = styled.p`
  font-size: 2rem;
  font-family: Inter;
  font-weight: bold;
  margin-top: ${({ theme }) => theme.spacing(0.25)};
`;

const LawyerPicture = styled.div`
  background-size: cover;
  background-position: center center;
  padding-top: 75.61%;
`;

const SocialLink = styled.a`
  color: inherit;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.secondary};
  }
`;

const Quote = styled.div`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLight};
`;

const ContentBody = styled.div`
  p {
    ${fontSize.body};
    line-height: 1.5;
  }

  > p:first-child {
    margin-top: 0;
  }

  > p:last-child {
    margin-bottom: 0;
  }

  img {
    margin: ${({ theme }) => theme.spacing(2)} auto;
    max-height: 600px;
    max-width: 100%;
    border-radius: 4px;
  }

  li {
    ${fontSize.body};
    line-height: 1.5;

    + li {
      margin-top: ${({ theme }) => theme.spacing()};
    }
  }

  a {
    color: ${({ theme }) => theme.secondary};
  }

  blockquote {
    box-shadow: inset 4px 0 0 rgba(0, 0, 0, 0.24);
    margin: ${({ theme }) => theme.spacing(2)} 0;
    padding: ${({ theme }) => theme.spacing()}
      ${({ theme }) => theme.spacing(2)};

    > p:first-child {
      margin-top: 0;
    }

    > p:last-child {
      margin-bottom: 0;
    }
  }
`;

const LawyerItem = ({ data, index }) => (
  <Wrapper id={data?.slug}>
    <Grid inverted={index % 2 === 0}>
      <PictureWrapper>
        <LawyerPicture
          style={{ backgroundImage: `url(${data?.photo?.url})` }}
        />
        <Stack marginTop={1.5} gutterSize={2.5} alignX="space-between">
          <Quote>{data?.quote}</Quote>
          {data?.linkedinLink && (
            <SocialLink
              href={data?.linkedinLink}
              target="_blank"
              rel="noopener, noreferrer"
            >
              <FaLinkedinIn size={20} />
            </SocialLink>
          )}
        </Stack>
      </PictureWrapper>
      <ContentWrapper>
        <Label>{data?.jobTitle}</Label>
        <LaywerName>{data?.name}</LaywerName>
        <ContentBody
          id="body"
          dangerouslySetInnerHTML={{
            __html: data?.descriptionNode?.childMarkdownRemark?.html,
          }}
        />
      </ContentWrapper>
    </Grid>
  </Wrapper>
);

export default LawyerItem;
