import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

export const formatDate = (date, frmt) =>
  format(new Date(date), frmt, { locale: fr });

export const isActive = ({ isCurrent }) =>
  isCurrent ? { className: 'active' } : {};

export const isPartiallyActive = ({ isPartiallyCurrent, ...props }) => {
  return isPartiallyCurrent ? { className: 'active' } : {};
};
