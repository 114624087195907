import React from 'react';
import styled from 'styled-components';
import { Container, List, media } from '@tymate/margaret';
import { graphql, useStaticQuery } from 'gatsby';
import { Section, Title, Heading, fontStyles, fontSize } from 'ui';
import { groupBy } from 'lodash';
import { MdArrowForward } from 'react-icons/md';

const AppointmentContent = styled.div`
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.text};
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${({ theme }) => theme.spacing(2)};

  ${({ variant }) =>
    variant === 'contactPage' &&
    `
      padding: 0px;
    `}

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(4)};
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${({ theme }) => theme.spacing(4)};

    ${({ variant }) =>
      variant === 'contactPage' &&
      `
        padding: 0;
      `}
  `};
`;

const Lawyer = styled.div`
  ${fontSize.body};
  line-height: 1.875;
  svg {
    color: ${({ theme }) => theme.secondary};
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const LawyerLink = styled.a`
  ${fontStyles.body};
  font-weight: 500;
  cursor: pointer;
  color: inherit;

  &:hover {
    color: ${({ theme }) => theme.secondary};
  }
`;

const Comment = styled.div`
  ${fontStyles.bodySmall};
  margin-top: ${({ theme }) => theme.spacing()};
`;

const AppointmentSection = ({ variant }) => {
  const {
    datoCmsHomepage,
    allDatoCmsLawyer,
    allDatoCmsLawFirm,
  } = useStaticQuery(
    graphql`
      query {
        datoCmsHomepage {
          appointmentTitle
        }
        allDatoCmsLawyer(sort: { fields: position, order: ASC }) {
          nodes {
            name
            skills {
              name
            }
            calendlyLink
            lawFirm {
              id
            }
          }
        }
        allDatoCmsLawFirm {
          nodes {
            id
            city
            comment
          }
        }
      }
    `,
  );

  const lawyers = allDatoCmsLawyer?.nodes.filter(({ lawFirm }) =>
    Boolean(lawFirm),
  );

  const groupedLawyers = groupBy(lawyers, ({ lawFirm }) => lawFirm?.id);

  const lawFirms = allDatoCmsLawFirm?.nodes.map(firm => ({
    ...firm,
    lawyers: groupedLawyers[firm.id],
  }));

  return (
    <Section background={variant !== 'contactPage' && 'secondary'}>
      <Container>
        <Title>{datoCmsHomepage?.appointmentTitle}</Title>
        <AppointmentContent variant={variant}>
          {lawFirms.map(({ id, city, lawyers, comment }) => (
            <div style={{ width: '100%' }} key={id}>
              <Heading>À {city}</Heading>
              <List direction="column" gutterSize={1}>
                {(lawyers ?? [])
                  .filter(({ calendlyLink }) => Boolean(calendlyLink))
                  .map(({ name, skills, calendlyLink, id }) => (
                    <Lawyer key={id}>
                      <MdArrowForward />
                      Avec {name} en{' '}
                      {calendlyLink ? (
                        <LawyerLink
                          href={calendlyLink}
                          target="_blank"
                          rel="noopener, noreferrer"
                        >
                          {skills.map(({ name }) => name).join(' ou ')}
                        </LawyerLink>
                      ) : (
                        <div>
                          {skills.map(({ name }) => name).join(' ou ')}{' '}
                        </div>
                      )}
                    </Lawyer>
                  ))}
              </List>
              <Comment>{comment}</Comment>
            </div>
          ))}
        </AppointmentContent>
      </Container>
    </Section>
  );
};

export default AppointmentSection;
