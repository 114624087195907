import React from 'react';
import { Avatar as AvatarMgt, Stack } from '@tymate/margaret';
import styled from 'styled-components';
import { FaLinkedinIn } from 'react-icons/fa';
import { RawLink } from 'ui';

const Avatar = styled(AvatarMgt)`
  height: 176px;
  width: 176px;
`;

const Name = styled.p`
  margin: 0;
  font-weight: 600;
  text-align: center;
`;

const JobTitle = styled.p`
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};
  color: ${({ theme }) => theme.textLight};
  text-align: center;
`;

const SocialLink = styled.a`
  color: ${({ theme }) => theme.text};

  &:hover {
    color: ${({ theme }) => theme.secondary};
  }
`;

const AvatarAndName = ({ user }) => (
  <Stack
    direction="column"
    gutterSize={1}
    alignX="center"
    style={{ maxWidth: 260 }}
  >
    <RawLink to={`/cabinet#${user?.slug}`} state={{ defaultIndex: 1 }}>
      <Avatar size="large" imageUrl={user?.photo?.url} />
    </RawLink>
    <Stack direction="column" alignX="center">
      <Name
        as={RawLink}
        to={`/cabinet#${user?.slug}`}
        state={{ defaultIndex: 1 }}
      >
        {user?.name}
      </Name>
      <JobTitle
        as={RawLink}
        to={`/cabinet#${user?.slug}`}
        state={{ defaultIndex: 1 }}
      >
        {user?.jobTitle}
      </JobTitle>
      {user?.linkedinLink && (
        <SocialLink
          href={user?.linkedinLink}
          rel="noopener, noreferrer"
          target="_blank"
        >
          <FaLinkedinIn size={20} />
        </SocialLink>
      )}
    </Stack>
  </Stack>
);

export default AvatarAndName;
