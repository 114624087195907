import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import { fontStyles, Label } from 'ui';
import { formatDate } from 'utils';

const ArticleWrapper = styled.li`
  padding-top: ${({ theme }) => theme.spacing(1.5)};
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  width: 100%;
  + * {
    border-top: 1px solid ${({ theme }) => theme.separator};
  }
`;

const ArticleLink = styled(Link)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  max-width: 45em;
`;

const ArticleTitle = styled.p`
  ${fontStyles.h3};
  margin: 0;
  font-weight: bold;
`;

const ArticleContent = styled.div`
  color: ${({ theme }) => theme.textLighter};
  padding-top: ${({ theme }) => theme.spacing(0.825)};
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  line-height: 1.75em;
`;

export const PublishedDate = styled.p`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLight};
`;

const ArticleCard = ({ article }) => (
  <ArticleWrapper>
    <ArticleLink to={`/blog/${article.slug}`}>
      <Stack direction="column">
        <Label>{article?.skill?.name}</Label>
        <ArticleTitle>{article?.title}</ArticleTitle>
        <ArticleContent>{article?.description}</ArticleContent>
        <PublishedDate>
          {formatDate(article?.meta?.publishedAt, 'd MMMM yyyy')}
        </PublishedDate>
      </Stack>
    </ArticleLink>
  </ArticleWrapper>
);

export default ArticleCard;
