import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'gatsby';
import { Button } from 'components';
import { ButtonReset } from '@tymate/margaret';
import { fontStyles } from 'ui';
import { HamburgerCollapseReverse } from 'react-animated-burgers';
import propulsedByPaprwork from 'images/propulsedByPaprwork.svg';

const HAMBURGER_DIMENSION = 26;

const ButtonWrapper = styled.div`
  img {
    width: 100%;
  }
`;

const Toggle = styled.div`
  padding: ${({ theme }) => theme.spacing()};
  min-height: calc(
    ${HAMBURGER_DIMENSION}px + 2 * ${({ theme }) => theme.spacing(0.5)} + 2 *
      ${({ theme }) => theme.spacing()}
  );

  > button {
    padding: ${({ theme }) => theme.spacing(0.5)};
    width: calc(
      ${HAMBURGER_DIMENSION}px + 2 * ${({ theme }) => theme.spacing(0.5)}
    );
    height: calc(
      ${HAMBURGER_DIMENSION}px + 2 * ${({ theme }) => theme.spacing(0.5)}
    );
    display: flex;
    align-items: center;
  }

  ${({ variant, theme }) =>
    variant === 'homepage' &&
    `
      background-color: ${theme.secondary};
    `};
`;

const Menu = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.primaryGradient};
  width: 260px;
  max-width: 60vw;
  padding: ${({ theme }) => theme.spacing(5)} ${({ theme }) => theme.spacing(2)}
    ${({ theme }) => theme.spacing(2)};
  z-index: 2;

  ${({ variant, theme }) =>
    variant === 'homepage' &&
    `
      background-color: ${theme.secondary};
    `};
`;

const Overlay = styled(ButtonReset)`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

const NavLink = styled(Link)`
  ${fontStyles.h3};
  text-decoration: none;
  color: ${({ theme }) => theme.text};
  font-weight: 600;
  text-decoration: none;
  transition: color 0.2s linear;

  &:hover {
    color: #fff;
  }

  + * {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const navListVariants = {
  open: {
    transform: 'translateX(100%)',
    transition: { ease: 'easeInOut', duration: 0.3 },
  },
  closed: {
    transform: 'translateX(0%)',
    transition: { ease: 'easeInOut', duration: 0.3 },
  },
};

const MobileMenu = ({ variant, setShownPaymentModal, data }) => {
  const [navIsShown, setNavIsShown] = useState(false);

  return (
    <>
      <Toggle variant={variant}>
        <HamburgerCollapseReverse
          isActive={navIsShown}
          barColor={'hsl(214, 15%, 25%)'}
          buttonWidth={HAMBURGER_DIMENSION}
          toggleButton={() => setNavIsShown(!navIsShown)}
          style={{ zIndex: 3, position: navIsShown ? 'fixed' : 'absolute' }}
        />
      </Toggle>

      <Menu
        variants={navListVariants}
        animate={navIsShown ? 'open' : 'closed'}
        variant={variant}
      >
        <NavLink to="/">Accueil</NavLink>
        <NavLink to="/cabinet">{data?.datoCmsLawFirmPage.tabName}</NavLink>
        <NavLink to="/offres">{data?.datoCmsOfferPage?.tabName}</NavLink>
        <NavLink to="/blog">{data?.datoCmsBlogPage?.tabName}</NavLink>
        <NavLink to="/coordonnees">{data?.datoCmsContactPage?.tabName}</NavLink>

        <NavLink
          as={ButtonReset}
          onClick={() => setShownPaymentModal(true)}
          style={{ textAlign: 'left', marginTop: 48 }}
        >
          Envoyer un paiement
        </NavLink>

        <ButtonWrapper>
          <Button variant="primary">Espace client</Button>
          <img src={propulsedByPaprwork} alt="Propulsé par Paprwork" />
        </ButtonWrapper>
      </Menu>

      <AnimatePresence>
        {navIsShown && (
          <Overlay
            as={motion.button}
            onClick={() => setNavIsShown(false)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.3 }}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileMenu;
