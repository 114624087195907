import styled, { css } from 'styled-components';
import { media, viewportSizes } from '@tymate/margaret';
import { keys, toPairs } from 'lodash';
import { Link } from 'gatsby';

export const theme = {
  textDark: '#111',
  text: '#212121',
  textLight: 'rgba(0, 0, 0, 0.5)',
  textLighter: '#8F8F8F',

  primary: '#000',
  primaryLight: 'rgba(0,0,0,0.5)',
  primaryGradient:
    'radial-gradient(circle, #DF145D 0%, #E20D59 52.88%, #E10A57 100%)',

  secondary: '#E10A57',
  secondaryLight: '#E20D59',
  secondaryLighter: '#fef1f6',

  success: '#29cc96',
  error: '#F14C66',

  buttonShadow: '0 8px 16px -8px #FF8B80',

  borderButtonRadius: '28px',
  borderRadius: '4px',

  background: '#F5F5F5',

  fonts: {
    title:
      'NeueMontreal-Bold, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
    body:
      'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
  },
  fontSizes: {
    h1Mega: {
      sizeMinRem: 2.5,
      sizeMaxRem: 3.5,
      lineHeightMin: 1.2,
      lineHeightMax: 1.1,
      fontFamilyKey: 'title',
      fontSize: 800,
    },
    h1: {
      sizeMinRem: 2,
      sizeMaxRem: 2.625,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
      fontFamilyKey: 'title',
      fontSize: 800,
    },
    h2: {
      sizeMinRem: 1.75,
      sizeMaxRem: 2.625,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
      fontFamilyKey: 'title',
      fontSize: 800,
    },
    h3: {
      sizeMinRem: 1.125,
      sizeMaxRem: 1.5,
      lineHeightMin: 1.4375,
      lineHeightMax: 1.25,
      fontFamilyKey: 'title',
      fontSize: 800,
    },
    bodyLarge: {
      sizeMinRem: 1.0625,
      sizeMaxRem: 1.1875,
      lineHeightMin: 1.3,
      lineHeightMax: 1.5,
    },
    body: {
      sizeMinRem: 1,
      sizeMaxRem: 1,
      lineHeightMin: 1.3125,
      lineHeightMax: 1.5,
    },
    bodySmall: {
      sizeMinRem: 0.8125,
      sizeMaxRem: 0.875,
      lineHeightMin: 1.4,
      lineHeightMax: 1.35,
    },
  },
};

const cssLock = ({
  valueUnit = '',
  minValue,
  maxValue,
  minViewportWidth = viewportSizes.tablet,
  maxViewportWidth = viewportSizes.desktop,
}) =>
  `calc((${minValue} * 1${valueUnit}) + (${maxValue} - ${minValue}) * (100vw - ${
    minViewportWidth / 16
  }em) / (${maxViewportWidth / 16} - ${minViewportWidth / 16}))`;

export const fontSize = toPairs(theme.fontSizes).reduce(
  (acc, [key, { sizeMinRem, sizeMaxRem, lineHeightMin, lineHeightMax }]) => ({
    ...acc,
    [key]: css`
      font-size: ${sizeMinRem}rem;

      ${media.tablet`
        font-size: ${cssLock({
          valueUnit: 'rem',
          minValue: sizeMinRem,
          maxValue: sizeMaxRem,
        })};
      `}

      ${media.desktop`
        font-size: ${sizeMaxRem}rem;
      `}
    `,
  }),
  {},
);

export const lineHeight = toPairs(theme.fontSizes).reduce(
  (acc, [key, { sizeMinRem, sizeMaxRem, lineHeightMin, lineHeightMax }]) => ({
    ...acc,
    [key]: css`
      line-height: ${lineHeightMin}em;

      ${media.tablet`
        line-height: ${cssLock({
          valueUnit: 'em',
          minValue: lineHeightMin,
          maxValue: lineHeightMax,
        })};
      `}

      ${media.desktop`
        font-size: ${sizeMaxRem}rem;
        line-height: ${lineHeightMax}em;
      `}
    `,
  }),
  {},
);

export const fontStyles = keys(theme.fontSizes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: css`
      ${fontSize[key]}
      ${lineHeight[key]}

      ${({ theme }) =>
        theme.fontSizes[key]?.fontFamilyKey &&
        css`
          font-family: ${({ theme }) =>
            theme.fonts?.[theme.fontSizes[key]?.fontFamilyKey]};
        `}
    `,
  }),
  {},
);

export const Section = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  ${media.desktop`
    padding-top: 100px;
    padding-bottom: 100px;
  `}

  ${({ background, theme }) =>
    background === 'light' &&
    `
      background-color: ${theme.background}
  `}

  ${({ background, theme }) =>
    background === 'secondary' &&
    `
      background-color: ${theme.secondary};
      color: #fff
    `}
`;

export const PageTitle = styled.h1`
  ${fontStyles.h1};
`;

export const Title = styled.h2`
  ${fontStyles.h2};
  margin-top: 0;

  span {
    font-weight: 300;
    display: block;
    font-family: Montserrat;
  }
`;

export const Heading = styled.h3`
  ${fontStyles.h3};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing()};
  font-weight: 700;
`;

export const Content = styled.div`
  ${fontSize.bodyLarge};
  line-height: 1.7em;
`;

export const SectionImage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  img {
    min-width: 0;
    max-width: 100%;
    max-height: 360px;
  }

  ${media.desktop`
    width: 32em;
    flex: 0 0 32em;

    img {
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  `}
`;

export const ArticleBody = styled.div`
  p {
    ${fontSize.bodyLarge};
    line-height: 1.7;
  }

  > p:first-child {
    margin-top: 0;
  }

  > p:last-child {
    margin-bottom: 0;
  }

  img {
    margin: ${({ theme }) => theme.spacing(2)} auto;
    max-height: 600px;
    max-width: 100%;
    border-radius: 4px;
  }

  li {
    ${fontSize.bodyLarge};
    line-height: 1.7;

    + li {
      margin-top: ${({ theme }) => theme.spacing()};
    }
  }

  a {
    color: ${({ theme }) => theme.secondary};
  }

  blockquote {
    box-shadow: inset 4px 0 0 rgba(0, 0, 0, 0.24);
    margin: ${({ theme }) => theme.spacing(2)} 0;
    padding: ${({ theme }) => theme.spacing()}
      ${({ theme }) => theme.spacing(2)};

    > p:first-child {
      margin-top: 0;
    }

    > p:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Label = styled.p`
  margin: 0;
  ${fontStyles.bodySmall};
  text-transform: uppercase;
  color: ${({ theme }) => theme.textLight};
  padding-bottom: ${({ theme }) => theme.spacing(0.25)};
`;

export const RawLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;
