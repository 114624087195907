import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Label } from 'ui';
import { Avatar as AvatarMgt, Stack, Modal, List } from '@tymate/margaret';
import styled from 'styled-components';

const Name = styled.p`
  margin: 0;
  font-weight: 600;
`;

const Lawyer = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(0.5)};
  width: 100%;
  border-radius: 4px;
  color: inherit;
  text-decoration: none;

  &:hover {
    background-color: ${({ theme }) => theme.secondaryLighter};
  }
`;

const Avatar = styled(AvatarMgt)`
  height: 48px;
  width: 48px;
  min-width: 48px;
`;

const PaymentModal = ({ isOpen, onRequestClose }) => {
  const { allDatoCmsLawyer, datoCmsContactPage } = useStaticQuery(graphql`
    query {
      datoCmsContactPage {
        paypalPaymentExplanation
      }
      allDatoCmsLawyer(sort: { fields: position, order: ASC }) {
        nodes {
          name
          jobTitle
          photo {
            alt
            url
          }
          paypalLink
        }
      }
    }
  `);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title="Envoyer un paiement"
      appElement="#___gatsby"
    >
      <Stack marginBottom={2}>
        {datoCmsContactPage?.paypalPaymentExplanation}
      </Stack>
      <List direction="column" gutterSize={1}>
        {allDatoCmsLawyer?.nodes
          .filter(({ paypalLink }) => Boolean(paypalLink))
          .map(({ name, jobTitle, photo, paypalLink }, index) => (
            <Lawyer
              key={index}
              gutterSize={1}
              alignY="center"
              as="a"
              href={paypalLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Avatar imageUrl={photo?.url} alt={photo?.alt} />
              <div>
                <Name>{name}</Name>
                <Label style={{ textTransform: 'none' }}>{jobTitle}</Label>
              </div>
            </Lawyer>
          ))}
      </List>
    </Modal>
  );
};

export default PaymentModal;
