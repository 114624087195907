import React from 'react';
import styled, { css } from 'styled-components';
import { Button as MgtButton } from '@tymate/margaret';
import { Link } from 'gatsby';

const LinkInjectorButton = props => {
  return (
    <MgtButton
      as={Boolean(props.to) ? Link : props.href ? 'a' : null}
      {...props}
    />
  );
};

const Button = styled(LinkInjectorButton)`
  ${({ variant }) =>
    variant === 'outline' &&
    css`
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary};

      &:hover {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondaryLight};
      }
    `}
`;

export default Button;
