import React from 'react';
import { createGlobalStyle } from 'styled-components';
import {
  Tabs as ReachTabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@reach/tabs';
import '@reach/tabs/styles.css';

export const TabsStyles = createGlobalStyle`
  [data-reach-tab-list] {
    background: transparent;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    white-space: nowrap;
    max-width: 100vw;
    overflow-x: auto;
  }

  [data-reach-tab-panel] {
    outline: none;
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }

  [data-reach-tab] {
    outline: none;
    background: transparent;
    padding: ${({ theme }) => theme.spacing(0.25)} 0;
    font-weight: 600;
    color: ${({ theme }) => theme.textLight};
    line-height: 1.75;

    +[data-reach-tab] {
      margin-left: ${({ theme }) => theme.spacing()}
    }

    &:active {
      background: transparent;
    }

    &:focus {
      color: ${({ theme }) => theme.text};
    }

    &[data-selected] {
      border-bottom: 0;
      box-shadow: inset 0 -2px 0 0 currentColor;
      color: ${({ theme }) => theme.text};

      &:focus {
        color: ${({ theme }) => theme.textDark};
      }
    }
  }

`;

const Tabs = ({ children, ...props }) => (
  <ReachTabs {...props}>
    <TabsStyles />

    {children}
  </ReachTabs>
);

export { Tabs, TabList, Tab, TabPanels, TabPanel };
