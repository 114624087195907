import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { theme } from 'ui';
import {
  MargaretProvider,
  media,
  viewportSizes,
  Stack,
} from '@tymate/margaret';
import { Button } from '.';
import styled, { css, createGlobalStyle } from 'styled-components';
import logoShort from 'images/logo-short.svg';
import logoShortInverted from 'images/logo-short-inverted.svg';
import logo from 'images/logo.svg';
import propulsedByPaprwork from 'images/propulsedByPaprwork.svg';
import 'sanitize.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/forms.css';
import 'typeface-montserrat';
import 'typeface-inter';
import MobileMenu from './MobileMenu';
import PaymentModal from './PaymentModal';
import { useWindowSize } from 'react-use';
import { FaLinkedinIn, FaTwitter, FaFacebookF } from 'react-icons/fa';
import CookieConsent from 'react-cookie-consent';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Montserrat, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    color: ${({ theme }) => theme.text};
    line-height: 1.5;
  }
  img {
    display: block;
    max-width: 100%;
  }
`;

const Site = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainStack = styled(Stack)`
  ${media.desktop`
    align-items: center;
  `}
`;

const Header = styled.header`
  display: none;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  height: 110px;
  padding: ${({ theme }) => theme.spacing(1.5)};

  ${media.tablet`
    display: flex;
    align-items: center;
  `}

  ${({ variant }) =>
    variant === 'homepage' &&
    css`
      background-color: ${({ theme }) => theme.secondary};

      ${NavLink} {
        color: #fff;
      }
    `};
`;

const NavLink = styled(Link)`
  color: ${({ theme }) => theme.text};
  font-weight: 400;
  text-decoration: none;
`;

const Logo = styled(Link)``;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing()};

  > * + * {
    margin-left: ${({ theme }) => theme.spacing(2.5)};
  }

  ${media.desktop`
    padding: ${({ theme }) => theme.spacing(2)}
    ${({ theme }) => theme.spacing(2.875)};
  `}
`;

const Main = styled.main`
  flex: 1;
`;

const ButtonWrapper = styled.div`
  img {
    width: 100%;
  }
`;

const Layout = ({ children, variant }) => {
  const { width } = useWindowSize();
  const isMobile = width < viewportSizes.tablet;
  const [shownPaymentModal, setShownPaymentModal] = useState(false);

  const {
    datoCmsBlogPage,
    datoCmsLawFirmPage,
    datoCmsOfferPage,
    datoCmsContactPage,
  } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      datoCmsBlogPage {
        tabName
      }
      datoCmsLawFirmPage {
        tabName
      }
      datoCmsOfferPage {
        tabName
      }
      datoCmsContactPage {
        tabName
        linkedinLink
        twitterLink
        facebookLink
        paprworkLink
        cookieBanner
        terms {
          url
          alt
        }
      }
    }
  `);

  const {
    linkedinLink,
    twitterLink,
    facebookLink,
    terms,
    paprworkLink,
    cookieBanner,
    tabName,
  } = datoCmsContactPage ?? {};

  return (
    <MargaretProvider theme={theme}>
      <GlobalStyle />

      <PaymentModal
        isOpen={shownPaymentModal}
        onRequestClose={() => setShownPaymentModal(false)}
      />

      <Site>
        <Header variant={variant}>
          <Stack gutterSize={2.5} alignY="center">
            <Logo to="/">
              <img
                src={variant === 'homepage' ? logoShortInverted : logoShort}
                alt="Essentiel A"
              />
            </Logo>
            <NavLink to="/cabinet">{datoCmsLawFirmPage.tabName}</NavLink>
            <NavLink to="/offres">{datoCmsOfferPage?.tabName}</NavLink>
            <NavLink to="/blog">{datoCmsBlogPage?.tabName}</NavLink>
            <NavLink to="/coordonnees">{tabName}</NavLink>
          </Stack>
          <ButtonWrapper>
            <Button
              variant="primary"
              href={paprworkLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Espace client
            </Button>
            <img src={propulsedByPaprwork} alt="Propulsé par Paprwork" />
          </ButtonWrapper>
        </Header>

        {isMobile && (
          <MobileMenu
            data={{
              datoCmsLawFirmPage,
              datoCmsOfferPage,
              datoCmsBlogPage,
              datoCmsContactPage,
            }}
            variant={variant}
            setShownPaymentModal={setShownPaymentModal}
          />
        )}

        <Main id="content">{children}</Main>

        <Footer>
          <Stack
            direction={{ default: 'column', tablet: 'row' }}
            alignX="space-between"
            size="full"
            gutterSize={2}
          >
            <MainStack
              direction={{ default: 'column', tablet: 'row' }}
              alignY={{ default: 'flex-start', desktop: 'center' }}
              gutterSize={2}
            >
              <Logo to="/">
                <img src={logo} alt="Essentiel A" />
              </Logo>

              <Stack
                direction={{ default: 'column', desktop: 'row' }}
                gutterSize={{ default: 0.5, desktop: 2.5 }}
                alignY="center"
              >
                <Stack
                  direction={{ default: 'column', desktop: 'row' }}
                  gutterSize={{ default: 0.5, desktop: 2.5 }}
                  alignY="center"
                >
                  <NavLink to="/cabinet">{datoCmsLawFirmPage.tabName}</NavLink>
                  <NavLink to="/offres">{datoCmsOfferPage?.tabName}</NavLink>
                  <NavLink to="/blog">{datoCmsBlogPage?.tabName}</NavLink>
                  <NavLink to="/coordonnees">{tabName}</NavLink>
                  {Boolean(terms) && (
                    <NavLink
                      as="a"
                      href={terms.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Mentions légales
                    </NavLink>
                  )}
                </Stack>
              </Stack>
              <Stack
                direction="row"
                gutterSize={{ default: 0.5, desktop: 1 }}
                alignY="center"
              >
                {Boolean(facebookLink) && (
                  <NavLink
                    as="a"
                    aria-label="Facebook"
                    target="blank"
                    rel="nofollow noopener noreferer"
                    href={facebookLink}
                  >
                    <FaFacebookF />
                  </NavLink>
                )}
                {Boolean(twitterLink) && (
                  <NavLink
                    as="a"
                    aria-label="Twitter"
                    target="blank"
                    rel="nofollow noopener noreferer"
                    href={twitterLink}
                  >
                    <FaTwitter />
                  </NavLink>
                )}
                {Boolean(linkedinLink) && (
                  <NavLink
                    as="a"
                    aria-label="LinkedIn"
                    target="blank"
                    rel="nofollow noopener noreferer"
                    href={linkedinLink}
                  >
                    <FaLinkedinIn />
                  </NavLink>
                )}
              </Stack>
            </MainStack>
            <Stack
              direction={{ default: 'column', desktop: 'row' }}
              gutterSize={{ default: 0.5, desktop: 1 }}
              alignY="center"
            >
              <Button
                variant="primary"
                onClick={() => setShownPaymentModal(true)}
              >
                Envoyer un paiement
              </Button>

              {/* <ButtonWrapper>
                <Button
                  variant="primary"
                  href={paprworkLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Espace client
                </Button>
                <img src={propulsedByPaprwork} alt="Propulsé par Paprwork" />
              </ButtonWrapper> */}
            </Stack>
          </Stack>
        </Footer>

        <CookieConsent
          location="bottom"
          buttonText="J’accepte"
          cookieName="Essentiel A cookie GA"
          style={{ background: theme.primary }}
          buttonStyle={{
            background: theme.secondary,
            color: '#fff',
            fontSize: '1rem',
          }}
          expires={150}
        >
          {cookieBanner}
        </CookieConsent>
      </Site>
    </MargaretProvider>
  );
};

export default Layout;
