import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { fontStyles } from 'ui';
import { PublishedDate } from './ArticleCard';
import { List, media } from '@tymate/margaret';
import { formatDate } from 'utils';

const LastArticles = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(2)};
  `}
`;

const LastArticlesTitle = styled.h3`
  ${fontStyles.h3};
  text-align: center;
  margin-top: 0;
`;

const ArticleWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.separator};
`;

const ArticleTitle = styled.p`
  font-weight: 600;
`;

const ArticleLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    ${ArticleTitle} {
      color: ${({ theme }) => theme.secondary};
    }
  }
`;

const LastArticlesList = ({ articles }) => (
  <LastArticles>
    <LastArticlesTitle>Nos derniers articles</LastArticlesTitle>
    <List direction="column">
      {articles.map(({ node }, index) => (
        <ArticleWrapper key={index}>
          <ArticleLink to={`/blog/${node.slug}`}>
            <ArticleTitle>{node?.title}</ArticleTitle>
            <PublishedDate>
              {formatDate(node?.meta?.publishedAt, 'd MMMM yyyy')}
            </PublishedDate>
          </ArticleLink>
        </ArticleWrapper>
      ))}
    </List>
  </LastArticles>
);

export default LastArticlesList;
