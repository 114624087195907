import React from 'react';
import styled from 'styled-components';
import { PageTitle } from 'ui';
import { media } from '@tymate/margaret';

const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.background};
  padding: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(2)};
  `}

  ${media.desktop`
    padding: ${({ theme }) => theme.spacing(4)};
  `}
`;

const MainGrid = styled.div`
  display: flex;
  flex-direction: column;

  ${media.tablet`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${({ theme }) => theme.spacing(2)};
  `}

  ${media.desktop`
    display: grid;
    grid-template-columns: 25% 1fr;
    grid-column-gap: ${({ theme }) => theme.spacing(4)};
  `}
`;

const ServicesList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${({ theme }) => theme.spacing(1)};

  ${media.desktop`
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${({ theme }) => theme.spacing(4)};
  `};
`;

const Service = styled.div``;

const ServicesSection = ({ data }) =>
  data.length > 0 && (
    <Wrapper>
      <MainGrid>
        <div>
          <PageTitle style={{ marginTop: 0 }}>Nos prestations</PageTitle>
        </div>
        <ServicesList>
          {data.map(({ name }, index) => (
            <Service key={index}>{name}</Service>
          ))}
        </ServicesList>
      </MainGrid>
    </Wrapper>
  );

export default ServicesSection;
